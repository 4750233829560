@include tablet-and-above {
  html,
  body {
    height: 100%;
    background-color: $background-light-base;
  }

  body > * {
    padding: 0 2rem;
  }

  .release-notes {
    margin-top: 1.75rem;
  }
}

@include tablet-and-below {
  body > * {
    padding: 0 1.25rem;
  }

  [class*='span-'] + [class*='span-'] {
    margin-top: 3rem; /*48px*/
    margin-left: 0 !important;
  }
}

:root .background-dark {
  background-color: $background-dark;
  color: $text-light-low;
}

body > section {
  overflow: hidden;
}

.wrapper {
  max-width: 71.25rem;
  margin: 0 auto;
}

section .wrapper {
  padding: 3.5rem 0; /*48px*/
}

section .wrapper.extra {
  padding: 6rem 0; /*96px*/
}

/*Product blocks*/
section .section-header {
  margin: 0 auto 3.5rem;
  text-align: center;
}

.section-footer {
  margin-top: 2.8125rem; /*45px*/
  text-align: center;
  position: relative;
  border-width: 1px 0 0;
  border-style: solid;
  border-image: linear-gradient(
      90deg,
      hsla(0, 0%, 90%, 0),
      hsla(0, 0%, 90%, 1) 50%,
      hsla(0, 0%, 90%, 0) 100%
    )
    2%;
}

.masthead .section-footer {
  border-style: solid;
  border-image: linear-gradient(
      90deg,
      hsla(0, 100%, 100%, 0),
      hsla(0, 100%, 100%, 0.15) 50%,
      hsla(0, 100%, 100%, 0) 100%
    )
    2%;
}

.wrapper.extra .section-footer {
  margin-bottom: -2.8125rem !important; /*Compensating 45px*/
}

/*Masthead styles for product sections*/
body .masthead {
  .hero.hero {
    display: inline-block;
    margin: 5.625rem -90rem 0; /*90px top -1440px left*/
  }
}

.masthead.background-dark {
  background-image: radial-gradient(
    80vw circle at 50% 10%,
    #3a2099 17%,
    #480c59 100%
  );
  position: relative;
}
