//
// Rem Function Helper
//
@function rem($px) {
  @return ($px/16px) * 1rem;
}

@mixin round-play-progress(
  $id,
  $duration,
  $size,
  $color,
  $border,
  $repeat: 1,
  $hover: running
) {
  #{$id},
  #{$id} * {
  }

  #{$id} {
    width: $size;
    height: $size;
  }

  #{$id} .pie {
    width: 50%;
    height: 100%;
    transform-origin: 100% 50%;
    position: absolute;
    background: $color;
    border: #{$border};
  }

  #{$id} .spinner {
    border-radius: 100% 0 0 100% / 50% 0 0 50%;
    z-index: 200;
    border-right: none;
  }

  #{$id}:hover .spinner,
  #{$id}:hover .filler,
  #{$id}:hover .mask {
    // animation-play-state: $hover;
    // transition: transform .1s;
  }

  #{$id} .spinner,
  #{$id} .filler {
    z-index: 100;
    transition: transform 0.15s;
  }

  #{$id} .filler {
    border-radius: 0 100% 100% 0 / 0 50% 50% 0;
    left: 50%;
    opacity: 0;
    z-index: 100;
    border-left: none;
  }

  #{$id} .mask {
    width: 50%;
    height: 100%;
    position: absolute;
    background: inherit;
    opacity: 1;
    z-index: 300;
  }

  // @keyframes rota {
  //   0% { transform: rotate(0deg); }
  //   100% { transform: rotate(360deg); }
  // }

  @keyframes opa {
    0% {
      opacity: 1;
    }
    50%,
    100% {
      opacity: 0;
    }
  }
}
