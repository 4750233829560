.secondary-nav {
  text-align: center;
  background: $background-dark;
}

.secondary-nav ul,
.secondary-nav ol {
  white-space: nowrap;
  overflow: auto;
  -webkit-mask-image: -webkit-linear-gradient(left, hsla(0,0%,0%,0) 0%, hsla(0,0%,0%,1) 4%, hsla(0,0%,0%,1) 92%, hsla(0,0%,0%,0) 100%);
}

.secondary-nav ul::-webkit-scrollbar,
.secondary-nav ol::-webkit-scrollbar {
  display: none;
}

  @include tablet-and-below {
    .secondary-nav {
      margin: 0 -1rem;
    }
  }

.secondary-nav li {
  margin-right: .25rem;
  display: inline-block;
}

  @include tablet-and-above {
    .secondary-nav {
      margin: 0 -2rem;
    }

    .secondary-nav li {
      margin-right: 3.5rem; /*56px (1col)*/
    }

    .secondary-nav li:last-child {
      margin-right: 0;
    }
  }

.secondary-nav a {
  display: inline-block;
  padding: 1.25rem 1rem; /*16px*/
  color: $text-light;
  &:hover,
  &:focus { color: $text-light-low; }
  &:active,
  &.active { color: $text-light-ultra-low; }
}
