/* @import must be at top of file, otherwise CSS will not work */
@import url('//hello.myfonts.net/count/3237e4');
@import url('//hello.myfonts.net/count/37a739');

@font-face {
  font-family: 'Eina01-SemiBold';
  src: url('/fonts/3237E4_0_0.eot');
  src: url('/fonts/3237E4_0_0.eot?#iefix') format('embedded-opentype');
  src: url('/fonts/3237E4_0_0.woff2') format('woff2'),
    url('/fonts/3237E4_0_0.woff') format('woff'),
    url('/fonts/3237E4_0_0.ttf') format('truetype');
}

@font-face {
  font-family: 'Eina01-Bold';
  src: url('/fonts/37A739_0_0.eot');
  src: url('/fonts/37A739_0_0.eot?#iefix') format('embedded-opentype');
  src: url('/fonts/37A739_0_0.woff2') format('woff2'),
    url('/fonts/37A739_0_0.woff') format('woff'),
    url('/fonts/37A739_0_0.ttf') format('truetype');
}
