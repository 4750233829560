.search-form {
  position: fixed;
  z-index: 1000;
  width: 100%;
  pointer-events: none;
  top: 4rem;
  opacity: 0;
  visibility: hidden;
  transform: translateY(-1rem);
  transition: transform 0.3s $ease-out-expo, opacity 0.2s ease-out,
    visibility 0s linear 0.5s;

  &.show {
    visibility: visible;
    opacity: 1;
    transform: translateY(0);
    transition: transform 0.5s $ease-out-expo, opacity 0.3s ease-out,
      visibility 0s linear 0s;
  }

  .search-contents {
    margin: 0 auto;
    pointer-events: all;
    position: relative;
  }

  .search-field {
    width: 100%;
    padding-top: 1rem;
    padding-bottom: 1rem;
    padding-right: 3rem;
    border: 0 !important;
    font-size: 1.625rem;
    line-height: 2rem;
    font-weight: 500;
    border-radius: 4px !important;
    box-shadow: 0 1px 1px $border-dark-high;
  }

  .search-close {
    display: block;
    position: absolute;
    top: 2rem;
    right: 0.5rem;
    width: 2.5rem;
    height: 2.5rem;
    margin-top: -1.25rem;
    background-image: url('data:image/svg+xml;utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2212%22%20height%3D%2212%22%20viewBox%3D%220%200%2012%2012%22%3E%3Cpath%20fill%3D%22%23000%22%20fill-rule%3D%22evenodd%22%20d%3D%22M6%204.926L1.3.226C1-.074.52-.074.223.223c-.3.3-.295.78.005%201.08L4.928%206l-4.7%204.7c-.3.3-.303.78-.006%201.078.3.3.78.295%201.08-.005L6%207.073l4.7%204.7c.3.3.78.302%201.078.005.3-.3.295-.78-.005-1.08L7.073%206l4.7-4.7c.3-.3.302-.78.005-1.078-.3-.3-.78-.295-1.08.005L6%204.927z%22%2F%3E%3C%2Fsvg%3E');
    background-position: center center;
    background-repeat: no-repeat;
    cursor: pointer;
    transition: opacity 0.25s ease-out;
    &:hover,
    &:active,
    &:focus {
      opacity: 0.5;
    }
  }

  .search-results {
    will-change: contents, scroll-position;
    position: relative;
    z-index: 101;
    background-color: rgba($background-dark, 0.96);
    margin-top: 1rem;
    border-radius: 4px;
    box-shadow: 0 1px 1px $border-dark-high;
    overflow: auto;
    max-height: 14.2rem;
    -webkit-overflow-scrolling: touch;

    ul {
      position: relative;
      border-top: 0;
    }

    li {
      display: block;

      & + li {
        border-top: 1px solid $border-light-low;
      }

      &:last-child {
        border-bottom: 0;
      }
    }

    a {
      display: block;
      padding: 1rem;
      position: relative;
      font-weight: 600;
      font-size: 1rem;
      line-height: 1.5rem;
      transition: background-color 0.15s, color 0.15s;
      color: $background-light-base;
      &:hover,
      &:active,
      &:focus {
        color: $background-light-base;
        background-color: $action; //must be solid to avoid overlapping

        &:after {
          opacity: 1;
        }
      }

      &::after {
        content: '';
        display: inline-block;
        width: 0.375rem; //6px
        height: 0.625rem; //10px
        position: absolute;
        right: 1.25rem;
        top: 50%;
        transform: translateY(-50%);
        background-image: url('data:image/svg+xml;utf8,%3Csvg%20width%3D%228%22%20height%3D%2213%22%20viewBox%3D%220%200%208%2013%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Ctitle%3EDisclosure%20Indicator%3C%2Ftitle%3E%3Cpath%20d%3D%22M0%2C1.5%20L1.5%2C0%20L7.29201249%2C5.79201249%20C7.68302319%2C6.18302319%207.68649149%2C6.81350851%207.29201249%2C7.20798751%20L1.5%2C13%20L0%2C11.5%20L4.29422684%2C7.20577316%20C4.68401459%2C6.81598541%204.68722761%2C6.18722761%204.29422684%2C5.79422684%20L0%2C1.5%20Z%22%20fill%3D%22%23fff%22%20fill-rule%3D%22evenodd%22%2F%3E%3C%2Fsvg%3E');
        background-size: contain;
        opacity: 0.32;
        transition: opacity 0.25s;
      }
    }

    span {
      display: block;
      padding: 1rem;
      position: relative;
      font-weight: 600;
      font-size: 1rem;
      line-height: 1.5rem;
      color: $background-light-base;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}

@include tablet-and-below {
  .search-form {
    padding: 1rem;
    top: 0;
  }

  .search-results {
    max-height: calc(100vh - 98px);
  }
}
