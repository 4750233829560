

#CookieConsent {
    padding: 0.5rem 2.5rem 0.5rem 1.5rem;
    font-size: 0.8125rem;
    line-height: 1.5;
    color: $text-dark;
    background-color: $background-white;
    position: fixed;
    bottom: 1.5rem;
    left: 50%;
    transform: translateX(-50%);
    border-radius: $radius-big;
    z-index: 1000;
    box-shadow: 0 1px 3px 0 rgba(39, 21, 102, 0.08),
      0 0 0 1px rgba(39, 21, 102, 0.07);
    width: calc(100% - 3.5rem);
    max-width: 440px;
    transition: visibility 0s linear 0s, opacity 0.5s $ease-out-expo,
      transform 0.5s $ease-out-expo;
    
    &.hide-banner {
        opacity: 0;
        visibility: hidden;
        transform: translateX(-50%) scale(0.98);
        transition: visibility 0s linear 0.5s, opacity 0.5s $ease-out-expo,
          transform 0.5s $ease-out-expo;
    }
    
    &::after {
        content: '';
        position: absolute;
        right: 0.75rem;
        top: 50%;
        transform: translateY(-50%);
        background-image: url(https://cdn.sketchapp.com/assets/components/icons/icon-close-small-dark.svg);
        background-position: center center;
        background-repeat: no-repeat;
        width: 1rem;
        height: 1rem;
        opacity: 0.24;
        transition: opacity 0.2s ease-out;
        cursor: pointer;
    }
    
    &:hover::after {
        opacity: 1;
    }
}

  