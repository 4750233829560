.kind-class,
.kind-interface,
.kind-function,
.kind-typedef,
.kind-variable,
.kind-external {
  //margin-left: 0.75em;
  display: inline-block;
  padding: .25rem;
  text-align: center;
  vertical-align: baseline;
  border-radius: $radius-small;
  margin-right: 0.25rem;
  font-weight: bold;
  font-size: 9px !important;
  line-height: 1;
}

.kind-class {
  color: #009800;
  background-color: #bfe5bf;
}

.kind-interface {
  color: #fbca04;
  background-color: #fef2c0;
}

.kind-function {
  color: #6b0090;
  background-color: #d6bdde;
}

.kind-variable {
  color: #eb6420;
  background-color: #fad8c7;
}

.kind-typedef {
  color: #db001e;
  background-color: #edbec3;
}

.kind-external {
  color: #0738c3;
  background-color: #bbcbea;
}


/* search box */
.search-box {
  width: 25%;
}

.search-box img {
  display: none;
}

.search-input {
  -webkit-appearance: none;
  appearance: none;
  -moz-appearance: none;
  padding: .5rem;
  font-size: .8125rem; //13px
  color: $text-dark-high;
  background-color: $background-white;
  background-image: url(images/loupe.svg);
  background-position: .75rem center;
  background-repeat: no-repeat;
  padding-left: 2.25rem;
  width: 100%;
  border: .0625rem solid $border-dark-high;
  border-radius: $radius-normal;
  transition: all .12s linear;
}

  .search-input:focus {
    border-color: $action-border;
  }

.search-result {
  position: absolute;
  right: 2rem;
  display: none;
  max-height: 300px;
  width: 40%;
  padding: 0;
  background: white;
  box-shadow: 0 0 1px $border-dark-high, 0 1px 4px 1px $border-dark-high;
  border-radius: $radius-normal;
  white-space: nowrap;
  overflow-y: scroll;
}

.search-result-import-path {
  color: #aaa;
  // font-size: 12px;
}

.search-result li {
  list-style: none;
  padding: .5rem 1rem;
}

.search-result li a {
  display: block;
}

.search-result li.selected {
  background-color: $action-ultra-low;
}

.search-result li.search-separator {
  background: $border-dark;
  color: $text-dark-high;
  font-size: .8125rem;
  font-weight: $font-regular;
}

@include tablet-and-above {
  //.api > .row > header,
  .api footer,
  .api .page-last-modified {
    width: 69.6969697%;
    margin-left: 30.3030297%;
  }

  .api > .row > header {
    padding: 0 .25rem .5rem;
    border-bottom: 1px solid $divider-dark;
    margin-top: -3rem;
    margin-bottom: 2rem;

    width: 100%;
    margin-left: 05.3030303%;

    // display: none;
  }

  .api > .row > header a {
    padding-top: .5rem;
    font-size: .8125rem;
    line-height: 1.23125rem;
    display: inline-block;
    margin-right: 1rem;
    color: $text-dark;
  }

    // Hiding the Home link
    .api > .row > header a[href="/reference/api/./"] {
      display: none;
    }

  .api .search-box {
    display: inline-block;
    float: right;
  }

  .api .navigation {
    margin-bottom: 3.5rem !important;
    width: 19.6969697%;
    margin-left: 05.3030303%;
  }

    .api .navigation::before {
      content: "Contents";
      display: block;
      margin-top: 1rem;
      margin-bottom: 1.125rem;
      letter-spacing: -0.02em;
      word-wrap: break-word;
      font-size: 1.625rem;
      line-height: 2rem;
      font-family: "Eina01-SemiBold-webfont", -apple-system, BlinkMacSystemFont, "Helvetica Neue", Helvetica, Arial, sans-serif;
    	font-weight: normal;
    	font-style: normal;
      color: #000;
    }


  .api .navigation li {
    max-width: 100%;
    margin: .125rem 0;
    overflow: hidden;
    text-overflow: ellipsis;
    display: list-item;
    font-size: .8125rem;
    line-height: 1.23125rem;
  }

  .api .content {
    width: 69.6969697%;
    margin-bottom: 3.5rem !important;
    margin-left: 05.3030303%;
  }

  .api .page-last-modified {
    margin-bottom: 3.5rem !important;
  }

  .header-notice {
    margin-top: .5rem;
    margin-bottom: 2rem;
    padding: 1rem;
    font-size: .8125rem; //13px
    color: $text-dark-ultra-low;
    background: #f9fafb;
    border: 1px solid rgba(0,0,0,0.06);
    border-radius: $radius-normal;
  }

  .header-notice code {
    background: transparent;
    padding: 0 0 .5rem;
    color: $text-dark;
    text-shadow: none;
    box-shadow: none;
  }

  h1:not([class]) + .description p:first-child {
    font-size: 1.25rem; /*20px*/
    line-height: 1.7125rem; /*27.4px*/
    margin: 2rem 0; /*32px*/
  }
}
