.sidebar-main-content section h2:first-child {
  padding-top: 3rem;
}

.sidebar-main-content {
  color: $text-dark;
}

// Avoid images overflowing the content
.sidebar-main-content img {
  max-width: 100%;
  height: auto;
}

.sidebar aside ol,
.sidebar aside ul,
.sidebar aside:not(.toc-nav) li,
.sidebar aside p {
  margin-bottom: 1rem;
}

// External links
.content a[href^="http://"]:not([href*="sketch.com"]):not([href*="bohemiancoding.com"]):not(.card),
.content a[href^="https://"]:not([href*="sketch.com"]):not([href*="bohemiancoding.com"]):not(.card) {
  white-space: nowrap;
}

.content a[href^="http://"]:not([href*="sketch.com"]):not([href*="bohemiancoding.com"]):not(.card):after,
.content a[href^="https://"]:not([href*="sketch.com"]):not([href*="bohemiancoding.com"]):not(.card):after {
  content: '\21F1'; //⇱
  display: inline-block;
  margin-left: 0.25rem;
  font-size: 0.75em;
  vertical-align: top;
  transform: scaleX(-1); //Flipping, as this char doesn't exist that way
}

.sidebar .sketch-news-learn h2 a::after {
  content: none !important;
}

@include tablet-and-above {
  .sidebar .row {
    display: flex;
    flex-wrap: nowrap;
  }

  .sidebar aside {
    min-width: 13rem;
  }

  .sidebar .content {
    .content-wrapper {
      margin: 0 auto;
      max-width: 46rem;
      width: 100%;
    }
  }

  .sidebar .sketch-news-learn .input-group {
    width: 50%;
  }
}
