/*Easier sizing*/
*,
*:before,
*:after {
  box-sizing: border-box;
}

*:last-child {
  margin-bottom: 0 !important;
}

@include tablet-and-above {
  .row {
    display: flex;
    flex-wrap: wrap;
    margin-left: -1.5rem;
    margin-right: -1.5rem;
    margin-bottom: -3rem !important; //Compensating columns' margin bottom

    &.center-h {
      justify-content: center;
    }

    /*Justify elements without known width ('.col' + no '.span-*' class*/
    &.justify {
      justify-content: space-between;
      justify-content: justify;
    }

    /*Distribute elements without known width ('.col' + no '.span-*' class*/
    &.distribute {
      justify-content: space-around;
    }

    &.col-reverse {
      flex-direction: column-reverse;
    }

    &.reverse {
      flex-direction: row-reverse;
    }

    &.center-v {
      align-items: center;
      margin-bottom: 0 !important;
    }
  }

  .col {
    margin-bottom: 3rem !important;
    /*Gap between columns*/
    padding-left: 1.5rem;
    padding-right: 1.5rem;

    &.center-h {
      justify-content: center;
    }

    .row.center-v > & {
      margin-bottom: 0 !important;
    }

    &.flex {
      display: flex;
    }
  }

  // Span sizes
  .span-1 {
    width: 8.3333%;
  }
  .span-2 {
    width: 16.6666%;
  }
  .span-3 {
    width: 25%;
  }
  .span-4 {
    width: 33.3333%;
  }
  .span-5 {
    width: 41.6666%;
  }
  .span-6 {
    width: 50%;
  }
  .span-7 {
    width: 58.3333%;
  }
  .span-8 {
    width: 66.6666%;
  }
  .span-9 {
    width: 75%;
  }
  .span-10 {
    width: 83.3333%;
  }
  .span-11 {
    width: 91.6666%;
  }
  .span-12 {
    width: 100%;
  }
}
