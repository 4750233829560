/*Basic link styles*/
a {
  text-decoration: none;
  transition: color 0.2s $ease-in-out-quad, border-color 0.2s $ease-in-out-quad,
    background-color 0.2s $ease-in-out-quad,
    background-image 0.2s $ease-in-out-quad;
  color: $action;
  font-weight: inherit;
  -webkit-tap-highlight-color: rgba($action-high, 0.08);
  &:hover,
  &:active,
  &:focus {
    color: $action-high;
  }
}

.background-dark a {
  color: $action-light;
  &:hover,
  &:active,
  &:focus {
    color: $action-light-high;
  }
}
