/* SCSS Variables */
$breakpoint_phone_width: 640px;
$breakpoint_tablet_width: 768px;
$breakpoint_web_height:   900px;

$phone-width: 640px;
$tablet-width: 768px;
$desktop-width: 1024px;

/*
Usage:

p {
  font-size: 16px;

  @include tablet {
    font-size: 18px;
  }

  @include desktop {
    font-size: 20px;
  }
}

*/

@mixin phone {
  @media screen and (max-width: #{$phone-width - 1px}) {
    @content;
  }
}

@mixin tablet-and-below {
  @media screen and (max-width: #{$tablet-width - 1px}) {
    @content;
  }
}

@mixin tablet {
  @media screen and (min-width: #{$phone-width}) and (max-width: #{$desktop-width - 1px}) {
    @content;
  }
}

@mixin tablet-and-above {
  @media screen and (min-width: #{$tablet-width}) {
    @content;
  }
}

@mixin desktop {
  @media screen and (min-width: #{$desktop-width}) {
    @content;
  }
}

@mixin tall {
  @media screen and (min-height: #{$breakpoint_web_height}) and (max-width: #{$desktop-width}) {
    @content;
  }
}

@mixin retina {
  @media
    only screen and (-webkit-min-device-pixel-ratio: 2),
    only screen and (min--moz-device-pixel-ratio: 2),
    only screen and (-o-min-device-pixel-ratio: 2/1),
    only screen and (min-device-pixel-ratio: 2),
    only screen and (min-resolution: 192dpi),
    only screen and (min-resolution: 2dppx) {
    @content;
  }
}

@mixin print {
  @media print {
    @content;
  }
}
