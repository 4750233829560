/*TOC navigation*/
.search-toggle {
  background-image: url('data:image/svg+xml;utf-8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2224%22%20height%3D%2224%22%20viewBox%3D%220%200%2024%2024%22%3E%3Cpath%20fill%3D%22%23fa6400%22%20fill-rule%3D%22evenodd%22%20d%3D%22M13.46%2014.39c-.78.586-1.748.933-2.8.933-2.573%200-4.66-2.087-4.66-4.66C6%208.086%208.087%206%2010.66%206c2.575%200%204.662%202.087%204.662%204.662%200%201.05-.348%202.02-.935%202.8.075.035.144.082.205.143l3.2%203.2c.275.275.28.717.004.992-.273.273-.72.27-.992-.003l-3.2-3.2c-.062-.06-.11-.13-.143-.205zM12%2024c6.627%200%2012-5.373%2012-12S18.627%200%2012%200%200%205.373%200%2012s5.373%2012%2012%2012zm-1.34-9.61c2.06%200%203.73-1.67%203.73-3.728%200-2.06-1.67-3.73-3.73-3.73-2.058%200-3.728%201.67-3.728%203.73s1.67%203.73%203.73%203.73z%22%2F%3E%3C%2Fsvg%3E');
  -webkit-transition: -webkit-filter 0.1s ease-out;
  transition: filter 0.1s ease-out;
  &:hover,
  &:active,
  &:focus {
    //http://stackoverflow.com/questions/29037023/how-to-calculate-required-hue-rotate-to-generate-specific-colour
    -webkit-filter: hue-rotate(1deg) saturate(117%) brightness(105%);
    filter: hue-rotate(1deg) saturate(117%) brightness(105%);
  }
}

@include tablet-and-above {
  .toc-nav {
    margin-top: 1rem;
  }

  .nav-toggle {
    display: inline-block;
    margin-top: 0;
  }

  .toc-nav header {
    position: relative;
  }

  .search-toggle {
    width: 1.5rem;
    height: 1.5rem;
    cursor: pointer;
    float: right;
    margin-top: 0.25rem;
    margin-right: 0;
  }
}

.toc-nav h1 {
  margin-bottom: 1.125rem;
}

.toc-nav h2,
.toc-nav nav > ol > li {
  font-size: 1.125rem;
  line-height: 1.5rem; //28px
  margin: 0;
  padding: 0.25rem 0;
}

.toc-nav h2 a {
  color: $action;
  -webkit-tap-highlight-color: rgba($action-high, 0.08);
  &:hover,
  &:active,
  &:focus {
    color: $action-high;
  }
}

@include tablet-and-above {
  .toc-nav nav > ol > li + li {
    margin: 1rem 0; //6px
  }
}

/*Active triangle indicator*/

.toc-nav li {
  max-width: 100%;
  //padding-left: .25rem; /*4px*/
  overflow: hidden;
  text-overflow: ellipsis;
  display: list-item;
  font-size: 0.875rem; //14px
  line-height: 1.25rem; //20px
  font-weight: 600;
}

@include tablet-and-above {
  .toc-nav {
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
    box-shadow: inset -1px 0 0 $divider-dark;
    margin-top: -1.5rem;
    margin-bottom: 2rem !important;
    position: relative;
  }

  .toc-nav > * {
    position: relative;
    z-index: 1;
  }

  .toc-nav::before,
  .toc-nav::after {
    content: '';
    display: block;
    width: 100%;
    height: 4rem;
    position: absolute;
    z-index: 0;
  }

  .toc-nav::before {
    top: 0;
    background: linear-gradient(
      180deg,
      $background-light,
      rgba($background-light, 0)
    );
  }

  .toc-nav::after {
    bottom: 0;
    background: linear-gradient(
      180deg,
      rgba($background-light, 0),
      $background-light
    );
  }

  .toc-nav li {
    padding: 0.25rem 0 0.25rem 0.5rem; //4px
    // font-family: -apple-system, BlinkMacSystemFont, "Helvetica Neue", Helvetica, Arial, sans-serif;
    // font-weight: $font-medium;
    // letter-spacing: normal;

    a:hover {
      color: rgba(0, 0, 0, 0.4);
    }

    a.active {
      color: $action;
    }
  }
}

@include tablet-and-below {
  .toc-nav {
    margin: -2.5rem -0.5rem 0;
    padding: 0.594rem 1rem;
    width: calc(100% + 1rem); //fucking hack | #MakeTheWebLessHackyAgain
    border: 1px solid $border-dark-high;
    border-radius: $radius-normal;
    position: relative;
  }

  .toc-nav nav.active {
    margin: 0;
  }

  .toc-nav ol {
    display: none;
  }

  .toc-nav li {
    line-height: 1.5;
  }

  .toc-nav h2,
  .toc-nav ol ol a {
    display: block;
    padding: 0.5rem 0;
  }

  .toc-nav .nav-toggle {
    display: inline-block;
    position: relative;
    cursor: pointer;
    font-size: 1rem;
    margin: -0.625rem 0rem -0.5625rem -1rem;
    padding: 1rem 0rem 0.75rem 1rem;

    &::after {
      content: '';
      display: block;
      width: 1rem;
      height: 1rem;
      position: absolute;
      top: 50%;
      margin-top: -0.5rem;
      right: -1.25rem;
      background-image: url('data:image/svg+xml;utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%2016%2016%22%3E%3Cg%20fill%3D%22none%22%20fill-rule%3D%22evenodd%22%3E%3Crect%20width%3D%2216%22%20height%3D%2216%22%20fill%3D%22none%22%2F%3E%3Cpath%20fill%3D%22%23000%22%20fill-opacity%3D%220.85%22%20fill-rule%3D%22nonzero%22%20d%3D%22M4.707%205.293c-.39-.39-1.024-.39-1.414%200-.39.39-.39%201.024%200%201.414l4%204c.39.39%201.024.39%201.414%200l4-4c.39-.39.39-1.024%200-1.414-.39-.39-1.024-.39-1.414%200L8%208.586%204.707%205.293z%22%2F%3E%3C%2Fg%3E%3C%2Fsvg%3E');
      background-repeat: no-repeat;
      background-size: cover;
      transition: transform 0.35s cubic-bezier(0.165, 0.84, 0.44, 1);
    }
  }

  .toc-nav .nav-toggle.active::after {
    border-bottom-color: $text-dark-high;
  }

  .search-toggle {
    width: 2.75rem;
    height: 2.75rem;
    cursor: pointer;
    position: absolute;
    right: 0.25rem;
    top: 1.75rem;
    transform: translateY(-50%);
    background-position: center center;
  }

  .toc-nav nav.active {
    margin-top: 0.5625rem;
    // border-top: 1px solid $divider-dark;
    padding-top: 0.5rem;
    position: relative;
  }

  .toc-nav nav.active::before {
    content: '';
    display: block;
    width: calc(100% + 2rem);
    height: 1px;
    background: $divider-dark;
    position: absolute;
    top: 0;
    left: -1rem;
  }

  .toc-nav a.active::before {
    line-height: 1;
  }

  .toc-nav nav > ol > li > a.active::before {
    margin-top: 0.25rem;
  }

  .toc-sidebar {
    max-height: none !important;
    height: auto !important;
  }
}

.glossary-nav ol {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

@include tablet-and-below {
  .glossary-nav {
    display: none;
  }
}
