.developer .content img {
  max-width: 100%;
  height: auto;
}

//Last modified pill
.page-last-modified {
  margin-top: 3rem;
  padding: 1rem;
  background: $background-light-base;
  border: 1px solid $border-dark;
  box-shadow: 0px 1px 3px $border-dark-low;
  border-radius: $radius-small;
  font-size: 0.8125rem;
  color: $text-dark-low;
}

@include tablet-and-below {
  .page-last-modified em {
    display: block;
    margin-bottom: 0.5rem;
  }
}

//Tables
.content > table,
.content div:not(.highlight) > table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
  border-radius: $radius-small;
  border: 1px solid $border-dark-high;
  box-shadow: 0 1px 3px $border-dark;
  color: $text-dark;
  margin-bottom: 1em;
  margin-top: 2rem;
}

.content > table th,
.content div:not(.highlight) > table th,
.content > table td,
.content div:not(.highlight) > table td {
  padding: 1rem;
}

.content > thead td,
.content div:not(.highlight) thead td {
  font-weight: $font-regular;
}

@include tablet-and-above {
  .content div:not(.highlight) > table td {
    // width: 50%;
    border-bottom: 1px solid $border-dark;
    vertical-align: top;
  }
}

// FIXME It's inserting more trs than it should
tr:nth-last-child(2) td {
  border-bottom: 0;
}

@include tablet-and-below {
  td {
    display: block;
  }

  td + td {
    padding-top: 0;
    border-bottom: 1px solid $border-dark;
  }

  td:empty {
    padding-bottom: 0;
  }
}

.developer .content footer {
  margin-top: 3rem;
  padding-top: 2.5rem;
  border-top: 1px solid $border-dark;
}

.developer .content footer .h5 {
  margin: 0 0 1rem;
}

article.post header {
  margin-bottom: 2em;
  h1,
  h2 {
    margin-bottom: 0.2em;
  }
  p.meta {
    color: $text-dark-ultra-low;
    margin-top: 0.3em;
  }
}

@media print {
  .page-last-modified {
    display: none;
  }
}

// Content specfic styling
// -----------------------
//
// Assistants publishing checklist:

blockquote.assistants-checklist ul {
  list-style-type: none;
}
blockquote.assistants-checklist ul li {
  margin-bottom: 0.6em;
  position: relative;
}
blockquote.assistants-checklist ul li:before {
  content: '';
  position: absolute;
  left: -1.2em;
  top: 0.25em;
  background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M3.75 7.75L7 11.25l5.25-6.5' stroke='%23000' stroke-width='1.5' fill='none' fill-rule='evenodd' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: center center;
  width: 16px;
  height: 16px;
}
