.stop-scrolling {
  overflow: hidden;
}

//iOS only workaround to prevent scrolling when we have the modal open, as overflow doesn't work on iOS. Scrolling position is saved in a JS variable, then restored when we remove the class so we can keep the scroll position
.stop-scrolling.ios {
  position: fixed;
  width: 100%;
}

.overlay {
  position: fixed;
  z-index: 98;
  width: 100%;
  height: 100%;
  background-color: rgba($background-dark, 0.75);
  top: 0;
  bottom: 0;
  opacity: 0;
  pointer-events: none;
  visibility: hidden;
  transition: opacity 0.35s $ease-out-expo, visibility 0 0.35s;
  will-change: transform, opacity;
  -webkit-backdrop-filter: blur(8px);
  backdrop-filter: blur(8px);
}

.overlay.active {
  opacity: 1;
  transition: opacity 0.35s, visibility 0s;
  visibility: visible;
  pointer-events: all;
}

.overlay.cover-all {
  z-index: 999; //To pass over the navigation bar
}
